
export type NETWORK = "magento" | "big-commerce" | "prestashop" | "pardot" | "amazondsp" | "criteo" | "awin" | "app-flyers" | "outbrains" | "the-trade-desk" | "bing-webmaster-tool" | "ad-roll" | "adjust" | "google-search-console" | "instagram-public-data" | "pinterest-organic" | "twitter-public-data" | "call-rail" | "adtraction" | "piwik" | "amazon-seller" | "apple-search-ads" | "youtube" | "adobe-analytics" | "brevo" | "hubspot" | "piano-analytics" | "stripe" | "google-play-store" | "app-store-connect" | "mailchimp" | "klaviyo" | "woocommerce"|"matomo"|"google-my-business" | "snapchat-ads" | "linked-in-page" | "shopify" | "tik-tok-analytics" | "instagram" | "pinterest-ads" | "google-ads" | "twitter-ads" |"youtube-ads" |"linkedin" |"tik-tok" |"bing-ads" |"google-merchant-center" |"google-analytics" | "facebook-ads" | "facebook-analytics" |  "instagram-ads" | "amazon-ads" | "slack" | "website" | "spotify-ads" | "reddit-ads" | "pipedrive" | "active-campaign" | "ad-form" | "facebook-public-data";

export const ADWORDS = "google-ads";
export const MAGENTO = "magento";
export const PRESTASHOP = "prestashop";
export const BIGCOMMERCE = "big-commerce";
export const AMAZON_DSP = "amazondsp";
export const AWIN = "awin";
export const CRITEO = "criteo";
export const APPFLYERS = "app-flyers";
export const THE_TRADE_DESK = "the-trade-desk";
export const OUTBRAINS = "outbrains";
export const BINGWEBMASTERTOOL = "bing-webmaster-tool";
export const ADROLL = "ad-roll";
export const ADJUST = "adjust";
export const GOOGLE_SEARCH_CONSOLE = "google-search-console";
export const CALL_RAIL = "call-rail";

export const APP_STORE_CONNECT = "app-store-connect";
export const ADOBE_ANALYTICS = "adobe-analytics";
export const GOOGLE_PLAY_STORE = "google-play-store";
export const TWITTER_PUBLIC_DATA = "twitter-public-data";

export const BREVO = "brevo";
export const STRIPE = "stripe";

export const PIANO_ANALYTICS = "piano-analytics";
export const MAILCHIMP = "mailchimp";
export const INSTAGRAM = "instagram";
export const INSTAGRAM_PUBLIC_DATA = "instagram-public-data";
export const PARDOT = "pardot";
export const TWITTER_ADS = "twitter-ads";
export const LINKEDIN = "linkedin";
export const LINKEDIN_COMPANY_PAGE = "linked-in-page";
export const TIK_TOK = "tik-tok";
export const YOUTUBE = "youtube";
export const TIK_TOK_ANALYTICS = "tik-tok-analytics";
export const BING = "bing-ads";
export const PINTEREST_ADS = "pinterest-ads";
export const PINTEREST_ORGANIC = "pinterest-organic";
export const GOOGLE_MERCHANT_CENTER = "google-merchant-center";
export const GOOGLE_MY_BUSINESS = "google-my-business";
export const GOOGLE_ANALYTICS = "google-analytics";
export const FACEBOOK = "facebook-ads";
export const FACEBOOK_PUBLIC_DATA = "facebook-public-data";
export const FACEBOOK_ANALYTICS = "facebook-analytics";
export const AMAZON_ADS = "amazon-ads";
export const AMAZON_SELLER = "amazon-seller";
export const SHOPIFY = "shopify";
export const SNAPCHAT_ADS= "snapchat-ads";
export const SPOTIFY_ADS= "spotify-ads";
export const PIPEDRIVE= "pipedrive";
export const HUBSPOT= "hubspot";
export const SLACK = "slack";
export const WEBSITE = "website";
export const WOOCOMMERCE = "woocommerce";
export const MATOMO = "matomo";
export const KLAVIYO = "klaviyo";
export const REDDIT_ADS = "reddit-ads";
export const ACTIVE_CAMPAIGN = "active-campaign";
export const AD_FORM = "ad-form";
export const APPLE_SEARCH_ADS = "apple-search-ads";
export const PIWIK = "piwik";
export const ADTRACTION = "adtraction";



