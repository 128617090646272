import React, { useState } from 'react';
import Select from 'react-select';
import { TrashIcon } from '@heroicons/react/outline';
import Button from '../../../../ui/components/button/button';
import { FILTER_FORM } from "../type";
import FieldDTO from "../../../../services/models/http/google-datastudio/field-dto";
import { ALL_FILTER, filterToString } from "../index";

type Props = {
  fields: Array<FieldDTO>;
  value: FILTER_FORM;
  onRemove: () => void;
  onChange: (value: FILTER_FORM) => void;
};

const Filter = ({ fields, value, onChange, onRemove }: Props) => {
  return (
    <div className="grid grid-cols-6 gap-y-4 gap-x-4 ">
      <div className="col-span-6">
        <label
          htmlFor="field"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Fields :
        </label>
        <Select
          onChange={(newValue) => {
            onChange({ ...value, field: newValue!.value });
          }}
          name="field"
          options={fields?.map((field) => ({
            value: field.getFieldID(),
            label: field.getName(),
          }))}
          classNamePrefix="react-select"
        />
      </div>
      <div className="col-span-3">
        <label
          htmlFor="field"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Filter Type :
        </label>
        <Select
          onChange={(newValue : any) => {
            onChange({ ...value, type: newValue!.value });
          }}
          name="field"
          options={ALL_FILTER.map((filter) => ({
            value: filter,
            label: filterToString(filter),
          }))}
          classNamePrefix="react-select"
        />
      </div>
        {
            value.type !== "is_not_empty" ? (
                <div className="col-span-3">
                    <label
                        htmlFor="value"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Value :
                    </label>
                    <input
                        onChange={(e) => onChange({ ...value, value: e.target.value })}
                        value={value.value}
                        type="text"
                        name="value"
                        id="value"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                    />
                </div>
            ) : null
        }
      <div className="col-span-6">
        <Button
          type="danger"
          onClick={(e) => {
            onRemove();
          }}
        >
          <TrashIcon className="h-4 w-4 mr-2" />
          Remove
        </Button>
      </div>
    </div>
  );
};

export default Filter;
