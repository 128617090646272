import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import GoogleCloudStorageDestination from "../../../models/http/data-connector/destination/google-cloud-storage";

class GoogleCloudStorageAPI extends API{
  serializer = () => ({
    _postOne : GoogleCloudStorageDestination,
    _put: GoogleCloudStorageDestination
  })
}

export default Proxy<GoogleCloudStorageAPI>(new GoogleCloudStorageAPI('/destinations/google-cloud-storage'));
