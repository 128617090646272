import Adwords from './img/google-ads.svg';
import Bing from './img/bing-logo.svg';
import MerchantCenter from './img/google-merchant-center.svg';
import GoogleAnalytics from './img/google-analytics.svg';
import Facebook from './img/facebook.svg';
import FacebookPage from './img/facebook_pages.svg';
import AppStoreConnect from './img/app-store-connect.svg';
import Amazon from './img/amazon.svg';
import Brevo from './img/brevo.svg';
import CallRail from './img/CallRail.svg';
import Slack from './img/slack.svg';
import Website from './img/website.svg';
import Hubspot from './img/hubspot.svg';
import Linkedin from './img/linkedin.svg';
import Youtube from './img/youtube.svg';
import Instagram from './img/instagram.svg';
import Twitter from './img/twitter-ads.svg';
import TikTok from './img/tiktok-logo.svg';
import Pinterest from './img/pinterest.svg';
import Shopify from './img/shopify.svg';
import GoogleMyBusiness from './img/google-my-business.svg';
import Snapchat from './img/snapchat.svg';
import Woocommerce from './img/woocommerce.svg';
import Matomo from './img/matomo.svg';
import SpotifyAds from './img/spotify-ads.svg';
import Klaviyo from './img/klaviyo.svg';
import Mailchimp from './img/mailchimp.svg';
import RedditAds from './img/reddit-ads.svg';
import Pipedrive from './img/pipedrive.svg';
import ActiveCampaign from './img/active-campaign.svg';
import AdForm from './img/ad-form.svg';
import GooglePlayStore from './img/google-play-store.svg';
import GoogleSearchConsole from './img/google-search-console.svg';
import PianoAnalytics from './img/piano-analytics.svg';
import Stripe from './img/stripe.svg';
import AppleSearchAds from './img/apple-search-ads.svg';
import AdobeAnalytics from './img/adobe-analytics.svg';
import AmazonSeller from './img/amazon-seller.svg';
import Piwik from './img/piwik.svg';
import Adtraction from './img/adtraction.svg';
import Adjust from './img/adjust.svg';
import AdRoll from './img/ad-roll.svg';
import AppFlyers from './img/app-flyers.svg';
import TheTradeDesk from './img/the-trade-desk.svg';
import Outbrains from './img/outbrain.svg';
import Salesforce from './img/salesforce.svg';
import Awin from './img/awin.svg';
import Criteo from './img/criteo.svg';
import Magento from './img/magento.svg';
import BigCommerce from './img/bigcommerce.svg';
import Prestashop from './img/prestashop.svg';

import * as NETWORK_CONSTANT from '../../../constant/network';

export const networkImageFromURI = (type: string) : string => {
    switch (type) {
        case NETWORK_CONSTANT.ADWORDS:
            return Adwords;
        case NETWORK_CONSTANT.STRIPE:
            return Stripe;
        case NETWORK_CONSTANT.HUBSPOT:
            return Hubspot;
        case NETWORK_CONSTANT.BREVO:
            return Brevo;
        case NETWORK_CONSTANT.CALL_RAIL:
            return CallRail;
        case NETWORK_CONSTANT.ADOBE_ANALYTICS:
            return AdobeAnalytics;
        case NETWORK_CONSTANT.GOOGLE_PLAY_STORE:
            return GooglePlayStore;
        case NETWORK_CONSTANT.PIANO_ANALYTICS:
            return PianoAnalytics;
        case NETWORK_CONSTANT.APP_STORE_CONNECT:
            return AppStoreConnect;
        case NETWORK_CONSTANT.GOOGLE_MY_BUSINESS:
            return GoogleMyBusiness;
        case NETWORK_CONSTANT.LINKEDIN:
            return Linkedin;
        case NETWORK_CONSTANT.LINKEDIN_COMPANY_PAGE:
            return Linkedin;
        case NETWORK_CONSTANT.BING:
        case NETWORK_CONSTANT.BINGWEBMASTERTOOL:
            return Bing;
        case NETWORK_CONSTANT.GOOGLE_MERCHANT_CENTER:
            return MerchantCenter;
        case NETWORK_CONSTANT.GOOGLE_ANALYTICS:
            return GoogleAnalytics;
        case NETWORK_CONSTANT.GOOGLE_SEARCH_CONSOLE:
            return GoogleSearchConsole;
        case NETWORK_CONSTANT.FACEBOOK:
        case NETWORK_CONSTANT.FACEBOOK_PUBLIC_DATA:
            return Facebook;
        case NETWORK_CONSTANT.FACEBOOK_ANALYTICS:
            return FacebookPage;
        case NETWORK_CONSTANT.AMAZON_ADS:
            return Amazon;
        case NETWORK_CONSTANT.SLACK:
            return Slack;
        case NETWORK_CONSTANT.WEBSITE:
            return Website;
        case NETWORK_CONSTANT.YOUTUBE:
            return Youtube;
        case NETWORK_CONSTANT.INSTAGRAM:
        case NETWORK_CONSTANT.INSTAGRAM_PUBLIC_DATA:
            return Instagram;
        case NETWORK_CONSTANT.TWITTER_ADS:
        case NETWORK_CONSTANT.TWITTER_PUBLIC_DATA:
            return Twitter;
        case NETWORK_CONSTANT.TIK_TOK:
            return TikTok;
        case NETWORK_CONSTANT.TIK_TOK_ANALYTICS:
            return TikTok;
        case NETWORK_CONSTANT.PINTEREST_ADS:
        case NETWORK_CONSTANT.PINTEREST_ORGANIC:
            return Pinterest;
        case NETWORK_CONSTANT.SHOPIFY:
            return Shopify;
        case NETWORK_CONSTANT.SNAPCHAT_ADS:
            return Snapchat;
        case NETWORK_CONSTANT.SPOTIFY_ADS:
            return SpotifyAds;
        case NETWORK_CONSTANT.WOOCOMMERCE:
            return Woocommerce;
        case NETWORK_CONSTANT.MATOMO:
            return Matomo;
        case NETWORK_CONSTANT.KLAVIYO:
            return Klaviyo;
        case NETWORK_CONSTANT.MAILCHIMP:
            return Mailchimp;
        case NETWORK_CONSTANT.REDDIT_ADS:
            return RedditAds;
        case NETWORK_CONSTANT.PIPEDRIVE:
            return Pipedrive;
        case NETWORK_CONSTANT.ACTIVE_CAMPAIGN:
            return ActiveCampaign;
        case NETWORK_CONSTANT.AD_FORM:
            return AdForm;
        case NETWORK_CONSTANT.APPLE_SEARCH_ADS:
            return AppleSearchAds;
        case NETWORK_CONSTANT.AMAZON_SELLER:
            return AmazonSeller;
        case NETWORK_CONSTANT.PIWIK:
            return Piwik;
        case NETWORK_CONSTANT.ADTRACTION:
            return Adtraction;
        case NETWORK_CONSTANT.ADJUST:
            return Adjust;
        case NETWORK_CONSTANT.ADROLL:
            return AdRoll;
        case NETWORK_CONSTANT.APPFLYERS:
            return AppFlyers;
        case NETWORK_CONSTANT.THE_TRADE_DESK:
            return TheTradeDesk;
        case NETWORK_CONSTANT.OUTBRAINS:
            return Outbrains;
        case NETWORK_CONSTANT.PARDOT:
            return Salesforce;
        case NETWORK_CONSTANT.AWIN:
            return Awin;
        case NETWORK_CONSTANT.CRITEO:
            return Criteo;
        case NETWORK_CONSTANT.MAGENTO:
            return Magento;
        case NETWORK_CONSTANT.BIGCOMMERCE:
            return BigCommerce;
        case NETWORK_CONSTANT.PRESTASHOP:
            return Prestashop;
    }
    throw new Error(`Unable to lod image for ${type} network`)
};
