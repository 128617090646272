import { useMutation, useQuery } from "react-query";
import GoogleBigQueryAPI from "../../services/repository/data-connector/destinations/google-big-query";
import MySQLAPI from "../../services/repository/data-connector/destinations/mysql";
import PostgresAPI from "../../services/repository/data-connector/destinations/postgres";
import RedshiftAPI from "../../services/repository/data-connector/destinations/redshift";
import AzureSQLAPI from "../../services/repository/data-connector/destinations/azure-sql";
import SnowflakeAPI from "../../services/repository/data-connector/destinations/snowflake";
import AmazonS3API from "../../services/repository/data-connector/destinations/amazon-s3";
import GoogleCloudStorageAPI from "../../services/repository/data-connector/destinations/google-cloud-storage";
import AzureBlobStorageAPI from "../../services/repository/data-connector/destinations/azure-blob-storage";
import GoogleBigQueryDestination, { PostGoogleBigQuery } from "../../services/models/http/data-connector/destination/google-big-query";
import Pagination from "../../services/models/http/paginated";
import DestinationAPI from "../../services/repository/data-connector/destination";
import MySQLDestination, { PostMySQL } from "../../services/models/http/data-connector/destination/mysql";
import PostgresDestination , { PostPostgres } from "../../services/models/http/data-connector/destination/postgres";
import AzureSQLDestination , { PostAzureSQL } from "../../services/models/http/data-connector/destination/azure-sql";
import RedshiftDestination, { PostRedshift } from "../../services/models/http/data-connector/destination/redshift";
import SnowflakeDestination, { PostSnowflake } from "../../services/models/http/data-connector/destination/snowflake";
import GoogleCloudStorageDestination , { PostGoogleCloudStorage } from "../../services/models/http/data-connector/destination/google-cloud-storage";
import AzureBlobStorageDestination, { PostAzureBlobStorage } from "../../services/models/http/data-connector/destination/azure-blob-storage";



export const usePostDestinationMutation = () => useMutation('destination', (data: PostGoogleBigQuery|PostMySQL|PostPostgres|PostRedshift|PostAzureSQL|PostSnowflake|PostGoogleCloudStorage|PostAzureBlobStorage)  => {
  if(data.type === "GOOGLE_BIG_QUERY"){
    return GoogleBigQueryAPI._postOne(data);
  }else if(data.type === "MYSQL"){
    return MySQLAPI._postOne(data);
  }else if(data.type === "POSTGRES"){
    return PostgresAPI._postOne(data);
  }else if(data.type === "REDSHIFT"){
    return RedshiftAPI._postOne(data);
  }else if(data.type === "AZURE_SQL"){
    return AzureSQLAPI._postOne(data);
  }else if(data.type === "SNOWFLAKE"){
    return SnowflakeAPI._postOne(data);
  }else if(data.type === "AMAZON_S3"){
    return AmazonS3API._postOne(data);
  }else if(data.type === "GOOGLE_CLOUD_STORAGE"){
    return GoogleCloudStorageAPI._postOne(data);
  }else if(data.type === "AZURE_BLOB_STORAGE"){
    return AzureBlobStorageAPI._postOne(data);
  }
  throw new Error(`Unable to write data for ${data.type} destination.`)
});

export const usePutDestinationMutation = (id: number) => useMutation(['destination', id], (data: PostGoogleBigQuery|PostMySQL|PostPostgres|PostRedshift|PostAzureSQL|PostSnowflake|PostGoogleCloudStorage|PostAzureBlobStorage)  => {
  if(data.type === "GOOGLE_BIG_QUERY"){
    return GoogleBigQueryAPI._put(id, data);
  }else if(data.type === "MYSQL"){
    return MySQLAPI._put(id, data);
  }else if(data.type === "POSTGRES"){
    return PostgresAPI._put(id, data);
  }else if(data.type === "REDSHIFT"){
    return RedshiftAPI._put(id, data);
  }else if(data.type === "AZURE_SQL"){
    return AzureSQLAPI._put(id, data);
  }else if(data.type === "SNOWFLAKE"){
    return SnowflakeAPI._put(id, data);
  }else if(data.type === "AMAZON_S3"){
    return AmazonS3API._put(id, data);
  }else if(data.type === "GOOGLE_CLOUD_STORAGE"){
    return GoogleCloudStorageAPI._put(id, data);
  }else if(data.type === "AZURE_BLOB_STORAGE"){
    return AzureBlobStorageAPI._postOne(data);
  }
  throw new Error(`Unable to write data for ${data.type} destination.`)
});

export const useDeleteDestinationMutation = (id: number) => useMutation(['destination', id], ()  => {
  return DestinationAPI._delete(id);
});

export const useDestination = (id: number) => useQuery(['destination', id], () : Promise<GoogleBigQueryDestination|MySQLDestination|AzureSQLDestination|SnowflakeDestination|RedshiftDestination|PostgresDestination|GoogleCloudStorageDestination|AzureBlobStorageDestination> => DestinationAPI._getOne(id));
export const useDestinationListing = (page: number, options = {}) => useQuery(['destinations', page, options], () : Promise<Pagination<GoogleBigQueryDestination|MySQLDestination|PostgresDestination|RedshiftDestination|AzureSQLDestination|SnowflakeDestination|GoogleCloudStorageDestination|AzureBlobStorageDestination>> => DestinationAPI._getAll({pagination: false, query : {page, ...options}}));
