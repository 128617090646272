import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { Destination, GOOGLE_DATA_STUDIO } from "../../constant/destination";

class AzureSQL extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => "AZURE_SQL";

  getName = (): string => "Azure SQL";

}

export default AzureSQL;
