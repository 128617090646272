import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { Destination, GOOGLE_DATA_STUDIO } from "../../constant/destination";

class Snowflake extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => "SNOWFLAKE";

  getName = (): string => "Snowflake";

}

export default Snowflake;
