import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import { NETWORK, PRESTASHOP } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { E_COMMERCE } from "../../constant/constant";

class Prestashop extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Prestashop";

  getNetworkIdentifier = (): NETWORK => PRESTASHOP;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => E_COMMERCE;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];


  connectionMethod(args: any): any {
    return {
      "storeUrl": args.storeUrl,
    }
  }
}

export default Prestashop;
