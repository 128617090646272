export type Destination = "GOOGLE_DATA_STUDIO" | "MICROSOFT_EXCEL" | "AZURE_BLOB_STORAGE" | "GOOGLE_CLOUD_STORAGE" | "AMAZON_S3" | "SNOWFLAKE" | "AZURE_SQL" | "REDSHIFT" |"POSTGRES" | "MYSQL" | "GOOGLE_SHEET" | "GOOGLE_BIG_QUERY" | "TABLEAU" | "POWERBI" | "API" | "JSON" | "CSV"

export const GOOGLE_DATA_STUDIO = "GOOGLE_DATA_STUDIO";
export const MICROSOFT_EXCEL = "MICROSOFT_EXCEL";
export const GOOGLE_CLOUD_STORAGE = "GOOGLE_CLOUD_STORAGE";
export const AZURE_BLOB_STORAGE = "AZURE_BLOB_STORAGE";
export const AMAZON_S3 = "AMAZON_S3";
export const SNOWFLAKE = "SNOWFLAKE";
export const AZURE_SQL = "AZURE_SQL";
export const GOOGLE_SHEETS = "GOOGLE_SHEET";
export const MYSQL = "MYSQL";
export const TABLEAU = "TABLEAU";
export const POWERBI = "POWERBI";
export const POSTGRES = "POSTGRES";
export const API = "API";
export const JSON = "JSON";
export const CSV = "CSV";
export const GOOGLE_BIG_QUERY = "GOOGLE_BIG_QUERY";
export const REDSHIFT = "REDSHIFT";
