import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import AzureBlobStorageDestination from "../../../models/http/data-connector/destination/azure-blob-storage";

class AzureBlobStorageAPI extends API{
  serializer = () => ({
    _postOne : AzureBlobStorageDestination,
    _put: AzureBlobStorageDestination
  })
}

export default Proxy<AzureBlobStorageAPI>(new AzureBlobStorageAPI('/destinations/azure-blob-storage'));
