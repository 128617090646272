import { JSONSchemaType } from "ajv/dist/types/json-schema";
import DestinationModel, { Destination, PostDestination } from "../destination";

export interface AmazonS3 extends Destination {
  accessKey: string,
  secretKey: string,
  bucketId: string,
  region: string,
}

export interface PostAmazonS3 extends PostDestination {
  accessKey: string,
  secretKey: string,
  bucketId: string,
  region: string,
}


class AmazonS3Destination extends DestinationModel<AmazonS3, PostAmazonS3> {

  getAccessKey() {
    return this.data.accessKey;
  }

  getSecretKey() {
    return this.data.secretKey;
  }

  getBucketId() {
    return this.data.bucketId;
  }

  getRegion() {
    return this.data.region;
  }


  getSchemaToSerialize(): JSONSchemaType<AmazonS3> {
    const schema = this.baseSchemaToSerialize();
    schema.properties = { ...schema.properties,
      "accessKey": {
        type: ["string"]
      },
      "secretKey": {
        type: ["string"]
      },
      "bucketId": {
        type: ["string"]
      },
      "region": {
        type: ["string"]
      }
    };

    return schema as JSONSchemaType<AmazonS3>;
  }

  getSchemaPostToValidate(): JSONSchemaType<PostDestination & PostAmazonS3> {
    const schema = this.baseGetSchemaPostToValidate();
    schema.properties = { ...schema.properties,
      "accessKey": {
        type: ["string"]
      },
      "secretKey": {
        type: ["string"]
      },
      "bucketId": {
        type: ["string"]
      },
      "region": {
        type: ["string"]
      }
    };

    return schema as JSONSchemaType<PostDestination & PostAmazonS3>;
  }

}

export default AmazonS3Destination;
