import { JSONSchemaType } from "ajv/dist/types/json-schema";
import DestinationModel, { Destination, PostDestination } from "../destination";

export interface GoogleCloudStorage extends Destination {
  jsonKey: string,
  bucketId: string,
}

export interface PostGoogleCloudStorage extends PostDestination {
  jsonKey: string,
  bucketId: string,
}


class GoogleCloudStorageDestination extends DestinationModel<GoogleCloudStorage, PostGoogleCloudStorage> {

  getBucketId() {
    return this.data.bucketId;
  }

  getJsonKey() {
    return this.data.jsonKey;
  }


  getSchemaToSerialize(): JSONSchemaType<GoogleCloudStorage> {
    const schema = this.baseSchemaToSerialize();
    schema.properties = { ...schema.properties,
      "bucketId": {
        type: ["string"]
      },
      "jsonKey": {
        type: ["string"]
      }
    };

    return schema as JSONSchemaType<GoogleCloudStorage>;
  }

  getSchemaPostToValidate(): JSONSchemaType<PostDestination & PostGoogleCloudStorage> {
    const schema = this.baseGetSchemaPostToValidate();
    schema.properties = { ...schema.properties,
      "bucketId": {
        type: ["string"]
      },
      "jsonKey": {
        type: ["string"]
      }
    };

    return schema as JSONSchemaType<PostDestination & PostGoogleCloudStorage>;
  }

}

export default GoogleCloudStorageDestination;
