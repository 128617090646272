import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { AZURE_BLOB_STORAGE, Destination, GOOGLE_CLOUD_STORAGE } from "../../constant/destination";

class AzureBlobStorage extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => AZURE_BLOB_STORAGE

  getName = (): string => "Azure Blob Storage";

}

export default AzureBlobStorage;
