import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { number, string } from "yup";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DataStreamModel from "../../../../services/models/http/data-connector/data-stream/data-stream";
import { DataStreamJobFormValue } from "../create";
import { DATE } from "../../api/type";
import FieldAPI from "../../../../services/repository/google-datastudio/field";
import Input from "../../../../ui/components/input/input";
import Button from "../../../../ui/components/button/button";
import { ALL_DATE, toString } from "../../api";
import CronSelector from "../../../../ui/components/cron";
import * as Yup from "yup";
import { pickBy } from "lodash";
import {
  DataStreamJob as HTTPDataStreamJob
} from "../../../../services/models/http/data-connector/data-stream/data-stream-job";

type DataStreamJob = {
  recurrency: string,
  hasToBeInitialized: boolean,
  date: DATE,
  startDate?: string,
  endDate?: string,
  dateInitialization?: DATE,
  startDateInitialization?: string,
  endDateInitialization?: string,
  datastreamJobDestinationOption : {
    tableName?: string
  },
}
type Props = {
  onSubmit: (data: Partial<DataStreamJob>) => void,
  saving: boolean,
  dataStream: DataStreamModel,
  defaultValue?: Partial<HTTPDataStreamJob>,
}

const Validation = Yup.object().shape({
  recurrency: string().required('You need to set a recurrency'),
  date: string().required('You need to set a date'),
  datastreamJobDestinationOption: Yup.object().shape({
    tableName: string().required('You need to set a date')
  })
});
const StepFour = ({ onSubmit, saving, defaultValue, dataStream}: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState();
  const initialValues = {
    recurrency: "0 0 * * *",
    date: undefined,
    hasToBeInitialized: undefined,
    startDate: undefined,
    endDate: undefined,
    dateInitialization: undefined,
    startDateInitialization: undefined,
    endDateInitialization: undefined,
    datastreamJobDestinationOption: {
      tableName: undefined
    }
  };


  const formik = useFormik<Partial<DataStreamJob>>({
    initialValues: defaultValue ? {...initialValues, ...pickBy(defaultValue, (value, key) => Object.keys(initialValues).includes(key))} : initialValues,
    validationSchema: Validation,
    onSubmit: async (values) => {
      console.log("Submit", values);
      try {
        onSubmit(values);
      } catch (e) {
        // @ts-ignore
        setError(e.message);
      }
    }
  });


  useEffect(() => {
    if(!formik.values.hasToBeInitialized){
      formik.setValues({
        ...formik.values,
        dateInitialization: undefined,
        startDateInitialization: undefined,
        endDateInitialization: undefined
      })
    }
  }, [formik.values.hasToBeInitialized]);

  useEffect(() => {
    if(formik.values.dateInitialization !== "CUSTOM"){
      formik.setValues({
        ...formik.values,
        startDateInitialization: undefined,
        endDateInitialization: undefined
      })
    }
  }, [formik.values.dateInitialization]);

  useEffect(() => {
    if(formik.values.date !== "CUSTOM"){
      formik.setValues({
        ...formik.values,
        startDate: undefined,
        endDate: undefined
      })
    }
  }, [formik.values.date]);

  console.log(formik.values, formik.errors);
  return (
    <div>
      <div className="space-y-6">
        <form onSubmit={formik.handleSubmit}>
          <div className="bg-white  shadow sm:rounded-md sm:overflow-hidden divide-y divide-y-2">
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3
                  className="text-lg leading-6 font-medium text-gray-900">{t("Fill the data for your deployment options")}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t("Configure how your data should be added to destination")}
                </p>
              </div>
            </div>
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <fieldset className="border border-dashed border-gray-300 p-3">
                <legend className="px-2">{t("Initial data fetching")}</legend>
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="hasToBeInitialized"
                      name="hasToBeInitialized"
                      checked={formik.values.hasToBeInitialized}
                      onChange={(e) => formik.setFieldValue("hasToBeInitialized", e.target.checked)}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="hasToBeInitialized" className="font-medium text-gray-900">
                      Do you need a initial data fetching ?
                    </label>{" "}
                  </div>
                </div>
                {formik.values.hasToBeInitialized ? <>
                  <div className="mt-4">
                    <label htmlFor="dateInitialization" className="mb-1 block text-sm font-medium leading-6 text-gray-900">
                      {t('Select the date period you want to extract your data')}
                    </label>
                    <Select
                      onChange={(newValue: any) => {
                        formik.setFieldValue("dateInitialization", newValue.value);
                      }}
                      name="dateInitialization"
                      menuPortalTarget={document.body}
                      defaultValue={
                        formik.values.dateInitialization
                          ? {
                            value: formik.values.dateInitialization,
                            label: toString(formik.values.dateInitialization)
                          }
                          : null
                      }
                      options={ALL_DATE.map((date) => ({
                        value: date,
                        label: toString(date)
                      }))}
                      classNamePrefix="react-select"
                    />
                  </div>
                  {formik.values.dateInitialization === "CUSTOM" ? (
                    <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 grid-cols-6">
                      <div className="col-span-3">
                        <label
                          htmlFor="date_start"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Date Start :
                        </label>
                        <div className="mt-1">
                          <input
                            onChange={(e) => {
                              formik.setFieldValue(
                                "startDateInitialization",
                                e.target.value
                              );
                            }}
                            value={formik.values.startDateInitialization}
                            type="date"
                            name="startDateInitialization"
                            id="startDateInitialization"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                          />
                        </div>
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="date_end"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Date End :
                        </label>
                        <div className="mt-1">
                          <input
                            onChange={(e) =>
                              formik.setFieldValue(
                                "endDateInitialization",
                                e.target.value
                              )
                            }
                            disabled={!formik.values.startDateInitialization}
                            value={formik.values.endDateInitialization}
                            min={formik.values.startDateInitialization}
                            type="date"
                            name="endDateInitialization"
                            id="endDateInitialization"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </> : null}
              </fieldset>
              <fieldset className="border border-dashed border-gray-300 p-3">
                <legend className="px-2">{t("Schedule your dump")}</legend>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t("Select the frequency of your dump :")}
                </label>
                <CronSelector value={formik.values.recurrency as string} onChange={(value) => {formik.setFieldValue("recurrency", value)}} />
                <div className="mt-4">
                  <label htmlFor="date" className="mb-1 block text-sm font-medium leading-6 text-gray-900">
                    {t('Select the data period you want to extract at each frequency')}
                  </label>
                  <Select
                    onChange={(newValue: any) => {
                      formik.setFieldValue("date", newValue.value);
                    }}
                    name="date"
                    menuPortalTarget={document.body}
                    defaultValue={
                      formik.values.date
                        ? {
                          value: formik.values.date,
                          label: toString(formik.values.date)
                        }
                        : null
                    }
                    options={ALL_DATE.map((date) => ({
                      value: date,
                      label: toString(date)
                    }))}
                    classNamePrefix="react-select"
                  />
                </div>
                {formik.values.date === "CUSTOM" ? (
                  <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 grid-cols-6">
                    <div className="col-span-3">
                      <label
                        htmlFor="date_start"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date Start :
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={(e) => {
                            formik.setFieldValue(
                              "startDate",
                              e.target.value
                            );
                          }}
                          value={formik.values.startDate}
                          type="date"
                          name="startDate"
                          id="startDate"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="date_end"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date End :
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={(e) =>
                            formik.setFieldValue(
                              "endDate",
                              e.target.value
                            )
                          }
                          disabled={!formik.values.startDate}
                          value={formik.values.endDate}
                          min={formik.values.startDate}
                          type="date"
                          name="endDate"
                          id="endDate"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </fieldset>
              <fieldset className="border border-dashed border-gray-300 p-3">
                <legend className="px-2">{t("Option of your destination")}</legend>
                <Input 
                  id="datastreamJobDestinationOption.tableName"
                  name="datastreamJobDestinationOption.tableName"
                  type="text" 
                  label={dataStream.getDestination().type === "AMAZON_S3" || dataStream.getDestination().type === "GOOGLE_CLOUD_STORAGE" || dataStream.getDestination().type === "AZURE_BLOB_STORAGE" ? "File name" : "Table name"}
                  value={formik.values.datastreamJobDestinationOption?.tableName}
                  onChange={formik.handleChange} 
                />
              </fieldset>
            </div>
            <div className="py-6 px-4 flex justify-end items-center">
              {
                error ? <div className="text-red-500 mr-4">
                  {error}
                </div> : null
              }
              <div>
                <Button loading={saving} disabled={!formik.isValid} type="primary" htmlType="submit">
                  {t("Save")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StepFour;
