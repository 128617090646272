import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useCompany, useMutationCompany } from "../../../hooks/company";
import { getCurrentUser } from "../../../hooks/user";
import CompanyModel from "../../../services/models/http/company/company";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import { NotificationManager } from "../../../ui/components/notification/notification";
import CurrentUser, { CurrentUserHTTP } from "../../../services/models/http/user/current-user";
import { useQueryClient } from "react-query";

type FormValue = {
  name: string
}

const Validation = Yup.object().shape({
  name: Yup.string().required(),
});

const General = ({company}: {company: CompanyModel}) => {
  const {t} = useTranslation();
  const mutateCompany = useMutationCompany();
  const queryClient = useQueryClient();

  const form = useFormik<FormValue>({
    initialValues: {
      name : company.getName()
    },
    validationSchema: Validation,
    onSubmit: async (values) => {
      try {
        const response = await mutateCompany.mutateAsync({ id: company.getId(), ...values })
        NotificationManager.success(t('Your company information has been saved'), t('The system has updated your company information.'));
        queryClient.cancelQueries(['company', company.getId()]);
        queryClient.setQueriesData(['company', company.getId()], (old) => (old as CompanyModel).update(response))

      }catch (e){
        NotificationManager.error(t('Unable to save the company'), t('There is an internal error when you try to save your profile information.'))
      }
    }
  });

  return (
    <form className="divide-y divide-gray-200 lg:col-span-9" onSubmit={form.handleSubmit}>
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">{t('Profile')}</h2>
          <p className="mt-1 text-sm text-gray-500">
            {t('This will modify your Catchr space name, not your billing information. To edit your billing information, go to the billing menu.')}
          </p>
        </div>
        <div className="mt-6 flex flex-col lg:flex-row">
          <div className="flex-grow space-y-6">
            <div>
              <Input
                id="name"
                type="text"
                name="name"
                label={t('Company Name : ')}
                defaultValue={form.initialValues.name}
                value={form.values.name}
                onChange={form.handleChange}
                error={form.errors.name}
                touched={form.touched.name}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 py-4 flex justify-end sm:px-6">
        <Button
          loading={mutateCompany.isLoading}
          disabled={mutateCompany.isLoading}
          type="primary-purple"
          htmlType="submit"
        >
          {t('Save')}
        </Button>
      </div>
    </form>
  );
};

export default General;
