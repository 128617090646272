import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { Destination, GOOGLE_CLOUD_STORAGE } from "../../constant/destination";

class GoogleCloudStorage extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => GOOGLE_CLOUD_STORAGE

  getName = (): string => "Google Cloud Storage";

}

export default GoogleCloudStorage;
