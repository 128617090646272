import React from "react";
import { Route, Routes } from "react-router-dom";
import Matomo from "./matomo";
import Shopify from "./shopify";
import Woocommerce from "./woocommerce";
import Klaviyo from "./klaviyo";
import ActiveCampaign from "./active-campaign";
import { SentryRoutes } from "../../../index";
import AppStoreConnect from "./app-store-connect";
import GooglePlayStore from "./google-play-store";
import PianoAnalytics from "./piano-analytics";
import Stripe from "./stripe";
import Brevo from "./brevo";
import AdobeAnalytics from "./adobe-analytics";
import AppleSearchAds from "./apple-search-ads";
import Adtraction from "./adtraction";
import Piwik from "./piwik";
import CallRail from "./call-rail";
import TwitterPublicData from "./twitter-public-data";
import InstagramPublicData from "./instagram-public-data";
import Adjust from "./adjust";
import BingWebmasterTool from "./bing-webmaster-tool";
import AppFlyers from "./app-flyers";
import Outbrains from "./outbrains";
import TheTradeDesk from "./the-trade-desk";
import Pardot from "./pardot";
import Awin from "./awin";
import Criteo from "./criteo";
import FacebookPublicData from "./facebook-public-data";
import AmazonSeller from "./amazon-seller";
import Magento from "./magento";
import Prestashop from "./prestashop";
import Bigcommerce from "./bigcommerce";

const AddOn = () => (
    <SentryRoutes>
      <Route path="/shopify" element={<Shopify />} />
      <Route path="/woocommerce" element={<Woocommerce />} />
      <Route path="/klaviyo" element={<Klaviyo />} />
      <Route path="/matomo" element={<Matomo />} />
      <Route path="/active-campaign" element={<ActiveCampaign />} />
      <Route path="/appstore-connect" element={<AppStoreConnect />} />
      <Route path="/google-play-store" element={<GooglePlayStore />} />
      <Route path="/piano-analytics" element={<PianoAnalytics />} />
      <Route path="/brevo" element={<Brevo />} />
      <Route path="/call-rail" element={<CallRail />} />
      <Route path="/stripe" element={<Stripe />} />
      <Route path="/adobe-analytics" element={<AdobeAnalytics />} />
      <Route path="/twitter-public-data" element={<TwitterPublicData />} />
      <Route path="/apple-search-ads" element={<AppleSearchAds />} />
      <Route path="/instagram-public-data" element={<InstagramPublicData />} />
      <Route path="/piwik" element={<Piwik />} />
      <Route path="/adjust" element={<Adjust />} />
      <Route path="/bing-webmaster-tool" element={<BingWebmasterTool />} />
      <Route path="/adtraction" element={<Adtraction />} />
      <Route path="/app-flyers" element={<AppFlyers />} />
      <Route path="/outbrains" element={<Outbrains />} />
      <Route path="/the-trade-desk" element={<TheTradeDesk />} />
      <Route path="/pardot" element={<Pardot />} />
      <Route path="/awin" element={<Awin />} />
      <Route path="/criteo" element={<Criteo />} />
      <Route path="/facebook-public-data" element={<FacebookPublicData />} />
      <Route path="/amazon-seller" element={<AmazonSeller />} />
      <Route path="/magento" element={<Magento />} />
      <Route path="/prestashop" element={<Prestashop />} />
      <Route path="/bigcommerce" element={<Bigcommerce />} />
    </SentryRoutes>
  );

export default AddOn;
