import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import { NETWORK, APP_STORE_CONNECT, MAGENTO } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { E_COMMERCE, STORE_APPLICATION, WEBSITE } from "../../constant/constant";

class Magento extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Magento";

  getNetworkIdentifier = (): NETWORK => MAGENTO;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => E_COMMERCE;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];


  connectionMethod(args: any): any {
    return {
      "accessToken": args.accessToken,
      "accessTokenSecret": args.accessTokenSecret,
      "storeUrl": args.storeUrl,
    }
  }
}

export default Magento;
