import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { Destination, AMAZON_S3 } from "../../constant/destination";

class AmazonS3 extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => AMAZON_S3

  getName = (): string => "Amazon S3";

}

export default AmazonS3;
