import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { merge } from "lodash";
import StepOne from "../step/step-1";
import { useDataStream } from "../../../../hooks/data-stream";
import StepTwo from "../step/step-2";
import StepThree from "../step/step-3";
import StepFour from "../step/step-4";
import { DATE } from "../../api/type";
import DataStreamJobAPI from '../../../../services/repository/data-connector/data-stream/data-stream-job';
import { NotificationManager } from "../../../../ui/components/notification/notification";


export type DataStreamJobFormValue = {
  name: string,
  authorizations: Array<{
    authorizationId: number,
    id: string,
  }>,
  fields: {
    [p: string]: string
  },
  recurrency: string,
  hasToBeInitialized: boolean,
  date: DATE,
  startDate?: string,
  endDate?: string,
  dateInitialization?: DATE,
  startDateInitialization?: string,
  endDateInitialization?: string,
  datastreamJobDestinationOption: {
    id?: string | number
    tableName?: string,
    partitionFieldId?: string|null,
    dataType?: string|null,
  }
}

const typeSelection = (type: string) => {
  switch (type){
    case "GOOGLE_BIG_QUERY":
      return "big_query";
    case "MYSQL":
      return "mysql";
    case "POSTGRES":
      return "postgres";
    case "REDSHIFT":
      return "redshift";
    case "AZURE_SQL":
      return "azure_sql";
    case "SNOWFLAKE":
      return "snowflake";
    case "AMAZON_S3":
      return "amazon_s3";
    case "GOOGLE_CLOUD_STORAGE":
      return "google_cloud_storage";
    case "AZURE_BLOB_STORAGE":
      return "azure_blob_storage";
    default:
      throw new Error(`Unable to get the type ${type}`);
  }
}
const DataStreamJobCreate = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const navigate = useNavigate();
  const datastream = useDataStream(id as unknown as number)
  const [step, setStep] = useState(0);
  const [saving, setSaving] = useState(false);
  const width = 100 / 3 * step + 4.5;

  const [value, setValue] = useState<Partial<DataStreamJobFormValue>>({});
  if(datastream.data === undefined){
    return null;
  }

  const create = async (value : DataStreamJobFormValue) => {
    setSaving(true);
    try {
      const response = await DataStreamJobAPI._postOne({
        datastream: `/api/datastreams/${id}/`,
        recurrency: value.recurrency,
        name: value.name,
        hasToBeInitialized: value.hasToBeInitialized,
        fields: value.fields,
        startDate: value.date === "CUSTOM" ? value.startDate : null,
        endDate: value.date === "CUSTOM" ? value.endDate : null,
        date: value.date,
        datastreamJobDestinationOption: {
          type: typeSelection(datastream.data.getDestination().type),
          tableName: value.datastreamJobDestinationOption.tableName,
          partitionFieldId: value.datastreamJobDestinationOption.partitionFieldId,
          dataType: value.datastreamJobDestinationOption.dataType,
        },
        authorizations: value.authorizations,
        options: [],
        dateInitialization: value.hasToBeInitialized ? value.dateInitialization : null,
        endDateInitialization: value.dateInitialization === "CUSTOM" && value.hasToBeInitialized ? value.endDateInitialization : null,
        startDateInitialization: value.dateInitialization === "CUSTOM" && value.hasToBeInitialized ? value.startDateInitialization : null,
      });
      NotificationManager.success(t('The job has been created.'), t('Your job has been created and currently running.'));
      navigate(`/data-connector/data-stream/${id}/jobs/${response.data.id}`)
    }catch (e){
      console.error(e);
      NotificationManager.error(t('Unable to create the job.'), t('There is an internal error system.'));
    }
    setSaving(false);

  }
  console.log("Value", value);
  return (
    <div className="flex flex-1 mt-2 flex-col">
      <div className="w-full">
        <h4 className="sr-only">Status</h4>
        <p className="text-xl font-medium text-gray-900">{t('Create a job for your data stream')}</p>
        <div className="mt-6" aria-hidden="true">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div className="h-2 rounded-full bg-indigo-600" style={{ width: `${width}%` }} />
          </div>
          <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
            <div className={step >= 0 ? `text-indigo-600` : ''}>{t('Basic information')}</div>
            <div className={`text-center ${step >= 1 ? `text-indigo-600` : ''}`}>{t('Account')}</div>
            <div className={`text-center ${step >= 2 ? `text-indigo-600` : ''}`}>{t('Fields')}</div>
            <div className={`text-right ${step >= 3 ? `text-indigo-600` : ''}`}>{t('Deployment options')}</div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {step === 0 ? <StepOne onSubmit={(data) => {setValue(data); setStep(1)}} /> : null}
        {step === 1 ? <StepTwo dataStream={datastream.data} onSubmit={(data) => {setValue(merge(value, data)); setStep(2)}} /> : null}
        {step === 2 ? <StepThree currentValue={value as DataStreamJobFormValue} dataStream={datastream.data} onSubmit={(data) => {setValue(merge(value, data)); setStep(3)}} /> : null}
        {step === 3 ? <StepFour dataStream={datastream.data} saving={saving} onSubmit={(data) => {setValue(merge(value, data)); create(merge(value, data) as DataStreamJobFormValue)}} /> : null}
      </div>
    </div>
  );
};

export default DataStreamJobCreate;
