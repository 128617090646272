import React, { useState } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { AxiosPromise } from "axios";
import { useNavigate } from "react-router-dom";
import { NETWORK } from "../../../../constant/network";
import DataStreamAPI from '../../../../services/repository/data-connector/data-stream/data-stream';
import Input from "../../../../ui/components/input/input";
import DestinationSelector from "../../../../ui/components/select/destination";
import Button from "../../../../ui/components/button/button";
import NetworkSelector from "../../../../ui/components/select/network";
import { useDestinationListing } from "../../../../hooks/destinations";
import SelectWithAvatar from "../../../../ui/components/select/select-avatar";
import DestinationProvider from "../../../../config/destination/destination-provider";
import DataStreamModel from "../../../../services/models/http/data-connector/data-stream/data-stream";

export type DataStream = {
  name: string,
  platform: NETWORK
  destination: string
};


export const Validation = Yup.object().shape({
  name:  Yup.string().required('You need to set a name to the connection.'),
  platform:  Yup.string().required('You need to set a platform type.'),
  destination:  Yup.string().required('You need to set a destination to the connection.'),
});

const DataStreamForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const destinations = useDestinationListing(0);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const initialValues = {
    name: undefined,
    platform: undefined,
    destination: undefined
  };
  const formik = useFormik<Partial<DataStream>>({
    initialValues,
    validationSchema: Validation,
    onSubmit: async (values) => {
      setSaving(true);
      setError(null);
      try {
        const result = await DataStreamAPI._postOne({...values, destination: `api/abstract_destinations/${values.destination}`}) as DataStreamModel;
        if(result.data.id){
          navigate(`/data-connector/data-stream/${result.data.id}`);
        }
      }catch (e){
        // @ts-ignore
        setError(e.message);
      }
      setSaving(false);
    }
  });
  return (
    <div className="space-y-6">
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-white  shadow sm:rounded-md divide-y divide-y-2">
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3
                className="text-lg leading-6 font-medium text-gray-900">{t("Create a new Data Stream") }</h3>
              <p className="mt-1 text-sm text-gray-500">
                {t("If you want to export data to Google Data Studio or Google Sheets, you do not need any data stream. Otherwise, just follow the step.")}
              </p>
            </div>
          </div>
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <Input
                id="name"
                name="name"
                label={t("Name :")}
                type="text"
                error={formik.errors.name}
                value={formik.values.name}
                touched={formik.touched.name}
                onChange={formik.handleChange}
                description={t("Set a name for the datastream.")}
              />
            </div>
            <div>
              <NetworkSelector
                value={formik.values.platform}
                onChange={(destination) => formik.setFieldValue("platform", destination)}
                label={t("Platform :")}
                placeholder={t("Select a platform")}
                mode="all"
              />
            </div>
            <div>
              <SelectWithAvatar
                onChange={(destination) => formik.setFieldValue("destination", destination)}
                list={destinations.data?.data.map(elem => ({name: elem.getName(), value: elem.getId().toString(), avatar:  DestinationProvider.getByPlatform(elem.getType())?.destinationImage() as  string })) || []}
                placeholder={t("Select a destination")}
                label={t("Destination :")}
                value={formik.values.destination}
              />
            </div>
          </div>

          <div className="py-6 px-4 flex justify-end items-center">
            {
              error ? <div className="text-red-500 mr-4">
                {error}
              </div> : null
            }
            <div>
              <Button loading={saving} type="primary" htmlType="submit">
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DataStreamForm;
