import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import SnowflakeDestination from "../../../models/http/data-connector/destination/snowflake";

class SnowflakeAPI extends API{
  serializer = () => ({
    _postOne : SnowflakeDestination,
    _put: SnowflakeDestination
  })
}

export default Proxy<SnowflakeAPI>(new SnowflakeAPI('/destinations/snowflake'));
