import {
  ArrowCircleUpIcon,
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  CurrencyDollarIcon, EyeIcon,
  LinkIcon,
  PencilIcon, PlusIcon, TrashIcon
} from "@heroicons/react/solid";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSegmentTrack } from "react-segment-analytics";
import { isEmpty } from "lodash";
import { useDataStream, useDataStreamJobListing, useDeleteJob, useDeleteStream } from "../../../../hooks/data-stream";
import NetworkProvider from "../../../../config/network/network-provider";
import DestinationProvider from "../../../../config/destination/destination-provider";
import Button from "../../../../ui/components/button/button";
import DataStreamModel from "../../../../services/models/http/data-connector/data-stream/data-stream";
import Async from "../../../../ui/helper/async";
import DataStreamJobModel from "../../../../services/models/http/data-connector/data-stream/data-stream-job";
import { ConfirmManager } from "../../../../ui/components/confirm/confirm";
import { NotificationManager } from "../../../../ui/components/notification/notification";

type DataStreamJobListingProps = {
  dataStreamModel: DataStreamModel
}

type JobRowProps = {
  dataStreamJob: DataStreamJobModel,
  onDelete: () => void
}
const JobRow = ({ dataStreamJob, onDelete }: JobRowProps) => {

  const track = useSegmentTrack();
  const {t} = useTranslation();

  const [deleting, setDeleting] = useState(false);

  const deleteMutation = useDeleteJob(dataStreamJob.getId());
  const deleteJob = async () => {
    track('Trying to delete job', {job_id: dataStreamJob.getId()});
    ConfirmManager.confirm({
      title: t("Confirm the deletion of the job"),
      description: t("Are you sure you want to delete this job ?"),
      validButton: t("Delete"),
      cancelButton: t("Cancel"),
      typeImg: "danger",
      onValid: async () => {
        setDeleting(true);
        try {
          track('Validate delete job', {job_id: dataStreamJob.getId()});
          await deleteMutation.mutateAsync();
          NotificationManager.success(t('The job has been deleted'), "");
          onDelete();
        } catch(e) {
          NotificationManager.error(t('Unable to delete the job'), t('An error occurred. Please try again.'))
        }
        setDeleting(false);
      }
    });
  }
  return (
    <tr key={dataStreamJob.getId()}>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">
          {dataStreamJob.getName()}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">
          {dataStreamJob.getSources().length}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">
          {new Date(dataStreamJob.getNextRun()).toLocaleString()}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">
          {!isEmpty(dataStreamJob.getLastLaunchedAt()) ? new Date(dataStreamJob.getLastLaunchedAt() as string).toLocaleString() : "Never"}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">
          {isEmpty(dataStreamJob.getLastLaunchedAt()) && dataStreamJob.getStatus() === "WAITING" ? "Initializing" : dataStreamJob.getStatus()}
        </div>
      </td>
      <td className="pr-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
        <Link to={`jobs/${dataStreamJob.getId()}`}>
          <Button
            type="primary"
          >
            <EyeIcon className="h-6 w-6 mr-2" />
            View
          </Button>
        </Link>
        <Button
          loading={deleting}
          onClick={deleteJob}
          type="danger"
        >
          <TrashIcon className="h-6 w-6 mr-2" />
          Delete
        </Button>
      </td>
    </tr>
  );
};

const DataStreamJobListing = ({ dataStreamModel }: DataStreamJobListingProps) => {
  const { t } = useTranslation();
  const jobs = useDataStreamJobListing(dataStreamModel.getId());
  return (
    <div className="mt-8 block">
      <div className="align-middle inline-block min-w-full">
        <Async {...jobs}>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
              <tr className="border-t border-gray-200 bg-white">
                <th
                  scope="col"
                  className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="lg:pl-2">{t("Name")}</span>
                </th>
                <th
                  scope="col"
                  className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("Accounts")}
                </th>
                <th
                  scope="col"
                  className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("Next Run")}
                </th>
                <th
                  scope="col"
                  className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("Last Run")}
                </th>
                <th
                  scope="col"
                  className="text-left able-cell px-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("Status")}
                </th>
                <th
                  scope="col"
                  className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider" />
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {jobs.data?.data.map((dataStreamJob: DataStreamJobModel) => <JobRow onDelete={() => jobs.refetch()} dataStreamJob={dataStreamJob} />)}
              </tbody>
            </table>
          </div>
        </Async>
      </div>
    </div>
  );
};
const DataStreamView = () => {
  const { id } = useParams();
  const datastream = useDataStream(id as unknown as number);
  const track = useSegmentTrack();
  const {t} = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const deleteMutation = useDeleteStream(datastream.data?.getId() as number);
  const navigate = useNavigate();
  const deleteDataStream = async () => {
    track('Trying to delete datastream', {id: datastream.data?.getId()});
    ConfirmManager.confirm({
      title: t("Confirm the deletion of the datastream"),
      description: t("Are you sure you want to delete this datastream and all the job associated ?"),
      validButton: t("Delete"),
      cancelButton: t("Cancel"),
      typeImg: "danger",
      onValid: async () => {
        setDeleting(true);
        try {
          track('Validate delete datastream', {id: datastream.data?.getId()});
          await deleteMutation.mutateAsync();
          NotificationManager.success(t('The datastream has been deleted'), "");
          navigate("/data-connector/data-stream");
        } catch(e) {
          NotificationManager.error(t('Unable to delete the datastream'), t('An error occurred. Please try again.'))
        }
        setDeleting(false);
      }
    });
  }
  return (
    <Async {...datastream}>
      <div className="-mx-6 flex flex-col min-w-0 flex-1 justify-start items-start">
        <div className="flex items-center justify-between bg-white p-4 w-full border-b border-gray-100">
          <div className="min-w-0 flex-1">
            <h2
              className="text-2xl flex items-center font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              <img src={NetworkProvider.getByPlatform(datastream.data?.getPlatform() as string)?.networkImage()}
                   className="h-10 w-10" alt="" />
              <span className="ml-2"> {datastream.data?.getName()}</span>
            </h2>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                {datastream.data?.getJobs().length} jobs runnable
              </div>

              <div className="mt-2 flex items-center text-sm text-gray-500">
                <ArrowCircleUpIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                Destination : {datastream.data?.getDestination() ? <span className="flex items-center ml-2"> <img className="h-4 w-4 mr-1" src={DestinationProvider.getByPlatform(datastream.data?.getDestination().type)?.destinationImage()} /> {datastream.data?.getDestination().name} </span> : "No destination"}
              </div>
            </div>
          </div>
          <div className="mt-5 flex lg:ml-4 lg:mt-0">
          <span className="hidden sm:block">
            <Link to="jobs/new">
              <Button type="primary">
                <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                Add Job
              </Button>
              </Link>
          </span>
            <span className="ml-3 hidden sm:block">
            <Button type="danger" loading={deleting} onClick={deleteDataStream}>
              <TrashIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-white" aria-hidden="true" />
              Delete
            </Button>
          </span>
          </div>
        </div>
        <div className="p-4 pt-0 w-full">
          <DataStreamJobListing dataStreamModel={datastream.data as DataStreamModel} />
        </div>
      </div>
    </Async>
  );
};

export default DataStreamView;

