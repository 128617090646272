import React, { useMemo, useState } from "react";
import qs from "query-string";
import axios, { AxiosError } from "axios";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { classNames } from "../../../services/helper";
import APIForm, { validation } from "./form";
import { DATE, FILTER, FORMAT, QUERY_FORM } from "./type";
import { useMe } from "../../../hooks/user";
import Loader from "../../../ui/components/loader/loader";
import { useConfigField, useGetField } from "../../../hooks/api";
import { NETWORK } from "../../../constant/network";
import FieldDTO from "../../../services/models/http/google-datastudio/field-dto";
import config from "../../../config/index";
import { usePopper } from "react-popper";
import ReactDOM from "react-dom";


export const ALL_FILTER: Array<FILTER> = [
  'equals',
  'not_equals',
  'contains',
  'not_contains',
  'lower_than',
  'greater_than',
  'is_not_empty',
];

export const filterToString = (type: FILTER) => {
  switch (type) {
    case 'equals':
      return 'Equals';
    case 'not_equals':
      return 'Not Equals';
    case 'contains':
      return 'Contains';
    case 'not_contains':
      return 'Not contains';
    case 'lower_than':
      return 'Lower than';
    case 'greater_than':
      return 'Greater than';
    case 'is_not_empty':
      return 'Is Not Empty';
    default:
      throw new Error(`Unknown type : ${type}`);
  }
};


export const ALL_DATE: Array<DATE> = [
  'TODAY',
  'YESTERDAY',
  'LAST_7_DAYS',
  'LAST_14_DAYS',
  'LAST_28_DAYS',
  'THIS_WEEK_START_SUNDAY',
  'LAST_WEEK_START_SUNDAY',
  'THIS_WEEK_START_MONDAY',
  'LAST_WEEK_START_MONDAY',
  'THIS_MONTH',
  'LAST_MONTH',
  'THIS_QUARTER',
  'LAST_QUARTER',
  'THIS_YEAR',
  'LAST_YEAR',
  'CUSTOM',
];
export const toString = (type: DATE) => {
  switch (type) {
    case 'TODAY':
      return 'Today';
    case 'YESTERDAY':
      return 'Yesterday';
    case 'LAST_7_DAYS':
      return 'Last seven days';
    case 'LAST_14_DAYS':
      return 'Last fourteen days';
    case 'LAST_28_DAYS':
      return 'Last twenty-eight days';
    case 'THIS_WEEK_START_SUNDAY':
      return 'This week start sunday';
    case 'LAST_WEEK_START_SUNDAY':
      return 'Last week start sunday';
    case 'THIS_WEEK_START_MONDAY':
      return 'This week start monday';
    case 'LAST_WEEK_START_MONDAY':
      return 'Last week start monday';
    case 'THIS_MONTH':
      return 'This month';
    case 'LAST_MONTH':
      return 'Last month';
    case 'THIS_QUARTER':
      return 'This quarter';
    case 'LAST_QUARTER':
      return 'Last quarter';
    case 'THIS_YEAR':
      return 'This year';
    case 'LAST_YEAR':
      return 'Last year';
    case 'CUSTOM':
      return 'Custom';
    default:
      throw new Error(`Unknown ${type} for data.`);
  }
};


function PlaceHolder() {
  return <div
    className=" flex flex-col justify-center items-center relative w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  >
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      stroke="currentColor"
      fill="none"
      viewBox="0 0 48 48"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
      />
    </svg>
    <span className="mt-2 block text-sm font-semibold text-gray-900">Please input all the required field on the left side</span>
  </div>;
}

function LoaderAPI(props: { active: boolean }) {
  return <div className="mt-4 flex flex-col justify-center items-center w-full min-h-0 h-96"><Loader
    active={props.active} /></div>;
}

const API = () => {
  const [table, setTab] = useState("query");
  const me = useMe();
  const [formValue, setFormValue] = useState<Partial<QUERY_FORM>>({ format: "json" });
  const [data, setData] = useState<{headers: FieldDTO[], data: Array<{[k: string] : any}>} | null>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const fields: ReturnType<typeof useGetField> | null = useGetField(
    formValue.source as NETWORK
  );

  const url = useMemo(() => {
    console.log("Valid", validation.isValidSync(formValue));
    if(validation.isValidSync(formValue)){
      const query: any = {
        format: formValue.format,
        platform: formValue.source,
        metrics: formValue.metrics?.join(","),
        date: formValue.date,
        dimensions: formValue.dimensions?.join(","),
        apikey: me.data?.getCompany().apiKey,
        accounts: JSON.stringify(formValue.accounts?.map(elem => ({id: elem.id, authorization_id: elem.authorization_id}))),
        filters: JSON.stringify(formValue.filters)
      }

      if(formValue.date === "CUSTOM"){
        query.start_date = formValue.custom_date?.start;
        query.end_date = formValue.custom_date?.end;
      }

      if(formValue.options && Object.keys(formValue.options).length > 0) {
        Object.keys(formValue.options).forEach(key => {
          let value = formValue.options[key as keyof typeof formValue.options];
          if(Array.isArray(value)) {
            value = value.join(",");
          }
          query[`options_${key}`] = value;
        })
      }
      return `${config.api.endpoint}/api/request?${qs.stringify(query,{skipNull: true, skipEmptyString: true, sort: false})}`;
    }
      return undefined
    
  }, [formValue]);
  const updateField = (queries: Partial<QUERY_FORM>) => {
    setFormValue({...queries, format: formValue.format});
  };

  const [copied, setCopied] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
  });

  return (
    <div className="max-h-0 -mx-4 sm:-mx-6 -mb-4 w-full flex-1 flex flex-col">
      <div className="w-full flex-1 flex ">
        <div className="w-full flex items-stretch">
          <select className="outline-none border-0 bg-white" value={formValue.format}
                  onChange={e => setFormValue({ ...formValue, format: e.target.value as FORMAT })}>
            <option value="json">JSON</option>
            <option value="csv">CSV</option>
            <option value="tableau">Tableau</option>
            <option value="powerbi">PowerBI</option>
          </select>
          <CopyToClipboard
            text={url ? url : ""}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 10000);
              console.log("copied", url);
            }}
          >
            <input
              ref={setReferenceElement}
              readOnly
              placeholder="Your URL"
              value={url}
              onClick={(e) => {
                console.log("select");
                e.currentTarget.select();
              }}
              type="text"
              className="flex-1 outline-none border-0 border-l border-r border-gray-200"
            />
          </CopyToClipboard>
          {ReactDOM.createPortal(
            <div ref={setPopperElement} style={{...styles.popper, zIndex: '99999'}} {...attributes.popper}>
              {copied ? (
                <div className="bg-gray-700 text-white text-sm rounded py-1 px-3">
                  Copied!
                </div>
              ) : null}
            </div>,
            document.querySelector("body") as HTMLBodyElement
          )}

          <button
            disabled={url === undefined}
            className={classNames("w-48 px-3 py-1.5 text-sm font-semibold leading-6 text-white", url === undefined || loading ? "bg-indigo-300 cursor-not-allowed" : "bg-indigo-600")}
            onClick={async () => {
              if (url !== undefined && !loading) {
                setLoading(true);
                setError(null);
                setData(null);
                try {
                  const data = await axios.get(url);
                  const headers = [...formValue.dimensions || [], ...formValue.metrics || []].map(elem => {
                    return fields?.data?.data.find(field => field.getFieldID() === elem) as FieldDTO;
                  });

                  const returnData = data.data.data ? data.data.data : data.data;
                  console.log(headers, returnData, data);
                  setData({
                    headers,
                    data: returnData
                  });
                } catch (error: AxiosError | any) {
                  console.error("Global", error);
                  if (error.response) {
                    console.error("Local response", error.response);
                    setError(error.response);
                  } else if (error.request) {
                    console.log("Request", error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                  }
                } finally {
                  setLoading(false);
                }
              }
            }}
          >
            Preview
          </button>
        </div>
      </div>
      <div className="w-full flex-1 border-t border-gray-200 flex">
        <section className="flex h-full min-w-0 flex-1 flex-col overflow-hidden order-last">
          <div className="flex-shrink-0 bg-white  border-b shadow-sm border-gray-200 py-2 px-4 " style={{height: '55px'}}>
            <div className="flex items-center space-x-4 w-full">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
               Your api key :
              </label>
              <div className="flex-1">
               <input
                 type="text"
                 name="apikey"
                 id="apikey"
                 defaultValue={me.data?.getCompany().apiKey}
                 disabled
                 className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 cursor-not-allowed bg-gray-50 text-gray-500 ring-gray-200 sm:text-sm sm:leading-6"
               />
              </div>
            </div>
          </div>
          <div className="flex-shrink-0 bg-white">
            <div className="flex mt-4 min-h-0 flex-1 overflow-y-auto">
                  {loading ? <LoaderAPI active={loading} /> : null}
                  {!loading && data === null && error === null ? <PlaceHolder /> : null}
                  { data !== null && loading === false ? <div className="flow-root w-full">
                    <div className="">
                      <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                          <thead>
                          <tr>
                            {
                              data?.headers.map(elem =>  <th
                                scope="col"
                                className="top-0 z-10 border-b border-gray-300 bg-white py-3.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                              >
                                {elem.getName()}
                              </th>)
                            }
                          </tr>
                          </thead>
                          <tbody>
                          {
                            data?.data.map((dataItem, idx) => <tr key={idx}>
                              {
                                data.headers.map(headerItem => <td
                                  className={classNames(
                                    idx !== data.data.length - 1 ? 'border-b border-gray-200' : '',
                                    'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'
                                  )}
                                >
                                  {dataItem[headerItem.getFieldID()] || 'N/A'}
                                </td>)
                              }
                            </tr>)
                          }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> : null}
                  { error !== null && loading === false ? <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{ error.data.type }</h3>
                        <div className="mt-2 text-sm text-red-700">
                          { error.data.message }
                        </div>
                      </div>
                    </div>
                  </div> : null}
            </div>
          </div>
        </section>
        <aside className="order-first block flex-shrink-0">
          <div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100">
            <div className="border-b shadow-sm border-gray-200">
              <nav className="shadow-sm flex bg-white w-full flex-1" aria-label="Tabs">
                  <a
                    key="query"
                    href="#"
                    onClick={() => setTab("query")}
                    className={classNames(
                      table === "query"
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                      "flex  justify-center w-0 flex-1 text-center whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                    )}
                    aria-current={table === "query" ? 'page' : undefined}
                  >
                    Query
                  </a>
                <a
                  key="saved"
                  href="#"
                  onClick={() => setTab("saved")}
                  className={classNames(
                    table === "saved"
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                    "hidden flex justify-center w-0 flex-1 text-center whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={table === "saved" ? 'page' : undefined}
                >
                  Saved
                </a>
              </nav>
              <div>
                {table === "query" ? <APIForm onChange={updateField} initialValues={formValue as QUERY_FORM} /> : null }
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default API;
