import {
  API,
  CSV,
  JSON,
  GOOGLE_BIG_QUERY,
  GOOGLE_DATA_STUDIO,
  GOOGLE_SHEETS,
  POWERBI,
  TABLEAU,
  MYSQL,
  POSTGRES,
  REDSHIFT,
  AZURE_SQL,
  SNOWFLAKE,
  AMAZON_S3,
  GOOGLE_CLOUD_STORAGE,
  AZURE_BLOB_STORAGE,
  MICROSOFT_EXCEL
} from "../../../constant/destination";
import GoogleDataStudio from './img/google-data-studio.svg';
import GoogleSheets from './img/google-sheets.svg';
import GoogleBigQuery from './img/google-big-query.svg';
import Api from './img/api.svg';
import Tableau from './img/tableau.svg';
import MySQL from './img/mysql.svg';
import PowerBI from './img/powerbi.svg';
import Postegres from './img/postgresql.svg';
import Redshift from './img/redshift.svg';
import AzureSQL from './img/azure-sql.svg';
import Snowflake from './img/snowflake.svg';
import AmazonS3 from './img/amazon-s3.svg';
import GoogleCloudStorage from './img/google-cloud-storage.svg';
import AzureBlobStorage from './img/azure-blob-storage.svg';
import MicrosoftExcel from './img/excel.svg';

/**
 * Destination Image URI
 * @param type
 */
export const destinationImageUri = (type: string) : string => {
  switch (type) {
    case GOOGLE_DATA_STUDIO:
      return GoogleDataStudio;
    case GOOGLE_BIG_QUERY:
      return GoogleBigQuery;
    case GOOGLE_SHEETS:
      return GoogleSheets;
    case API:
    case JSON:
    case CSV:
      return Api;
    case TABLEAU:
      return Tableau;
    case POWERBI:
      return PowerBI;
    case MYSQL:
      return MySQL;
    case POSTGRES:
      return Postegres;
    case REDSHIFT:
      return Redshift;
    case AZURE_SQL:
      return AzureSQL;
    case SNOWFLAKE:
      return Snowflake;
    case AMAZON_S3:
      return AmazonS3;
    case GOOGLE_CLOUD_STORAGE:
      return GoogleCloudStorage;
    case AZURE_BLOB_STORAGE:
      return AzureBlobStorage;
    case MICROSOFT_EXCEL:
      return MicrosoftExcel;
    default:
      throw new Error('Not available destination');
  }
}
