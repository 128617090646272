import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import Input from "../../../../ui/components/input/input";

export type SnowflakeOptionsType = {
  type: "SNOWFLAKE",
  host: string,
  database: string,
  username: string,
  password: string
}

export const Validation = Yup.object().shape({
  host:  Yup.string().required('You need to put a host.'),
  username:  Yup.string().required('You need to put a username.'),
  password:  Yup.string().required('You need to put a password.'),
  database:  Yup.string().required('You need to put a database.'),
});


const SnowflakeOptions = ({ formik } : {formik: any}) => {
  const { t } = useTranslation();
  return (
    <div className="py-6 px-4 space-y-6 sm:p-6">
      <div>
        <Input
          id="host"
          name="host"
          type="text"
          label={t('Host')}
          error={formik.errors.options && formik.errors.options.host}
          touched={formik.touched.options && formik.touched.options.host}
          description={t('Input the host of your Snowflake database')}
          value={formik.values.options.host}
          onChange={(e) => formik.setFieldValue('options.host', e.target.value)}
        />
      </div>
      <div>
        <Input
          id="username"
          name="username"
          type="text"
          label={t('Username')}
          error={formik.errors.options && formik.errors.options.username}
          touched={formik.touched.options && formik.touched.options.username}
          description={t('Input the username of your Snowflake database')}
          value={formik.values.options.username}
          onChange={(e) => formik.setFieldValue('options.username', e.target.value)}
        />
      </div>
      <div>
        <Input
          id="password"
          name="password"
          type="text"
          label={t('Password')}
          error={formik.errors.options && formik.errors.options.password}
          touched={formik.touched.options && formik.touched.options.password}
          description={t('Input the password of your Snowflake database')}
          value={formik.values.options.password}
          onChange={(e) => formik.setFieldValue('options.password', e.target.value)}
        />
      </div>
      <div>
        <Input
          id="database"
          name="database"
          type="text"
          label={t('Database')}
          error={formik.errors.options && formik.errors.options.database}
          touched={formik.touched.options && formik.touched.options.database}
          description={t('Input the database of your Snowflake database')}
          value={formik.values.options.database}
          onChange={(e) => formik.setFieldValue('options.database', e.target.value)}
        />
      </div>
    </div>
  );
};

export default SnowflakeOptions;
