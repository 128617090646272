import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import Input from "../../../../ui/components/input/input";

export type AzureBlobStorageOptionsType = {
  type: "AZURE_BLOB_STORAGE",
  connectionString: string,
  bucketId: string,
}

export const Validation = Yup.object().shape({
  bucketId:  Yup.string().required('You need to put your bucket Id.'),
  connectionString:  Yup.string().required('You need to put your Connection String.'),
});


const AzureBlobStorageOptions = ({ formik } : {formik: any}) => {
  const { t } = useTranslation();
  return (
    <div className="py-6 px-4 space-y-6 sm:p-6">
      <div>
        <Input
          id="connectionString"
          name="connectionString"
          type="text"
          label={t('Connection String')}
          error={formik.errors.options && formik.errors.options.connectionString}
          touched={formik.touched.options && formik.touched.options.connectionString}
          description={t('Input the Json Key of your Azure Connection String')}
          value={formik.values.options.connectionString}
          onChange={(e) => formik.setFieldValue('options.connectionString', e.target.value)}
        />
      </div>
      <div>
        <Input
          id="bucketId"
          name="bucketId"
          type="text"
          label={t('Container')}
          error={formik.errors.options && formik.errors.options.bucketId}
          touched={formik.touched.options && formik.touched.options.bucketId}
          description={t('Input the bucket ID of your Azure Cloud Storage Container ID')}
          value={formik.values.options.bucketId}
          onChange={(e) => formik.setFieldValue('options.bucketId', e.target.value)}
        />
      </div>
    </div>
  );
};

export default AzureBlobStorageOptions;
