import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import AzureSQLDestination from "../../../models/http/data-connector/destination/azure-sql";

class AzureSQLAPI extends API{
  serializer = () => ({
    _postOne : AzureSQLDestination,
    _put: AzureSQLDestination
  })
}

export default Proxy<AzureSQLAPI>(new AzureSQLAPI('/destinations/azure-sql'));
