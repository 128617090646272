import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { Destination, GOOGLE_DATA_STUDIO, MICROSOFT_EXCEL } from "../../constant/destination";

class GoogleDataStudio extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => MICROSOFT_EXCEL;

  getName = (): string => "Microsoft Excel";

}

export default GoogleDataStudio;
