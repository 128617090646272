import API from "../../api/http/client";
import CurrentUserModel from '../../models/http/user/current-user'
import Proxy from "../../api/http/proxy";

class CurrentUser extends API {

    public _getMe() : Promise<CurrentUserModel> {
        return this.getClient().get('/me');
    }

    public serializer = () => ({
        '_getMe': CurrentUserModel,
        '_put': CurrentUserModel,
    });

    public getBillingPortalSession(){
        return this.getClient().post(`${this.endpoint}/create-billing-session`)
    }

    public getOneClickAuth(uuid: string){
        return this.getClient().post(`${this.endpoint}/auth/one_click_authenticator`, {id: uuid}, {headers: {"Content-type": "application/ld+json"}})
    }

    public getCheckoutSession(data: any){
        return this.getClient().post(`${this.endpoint}/create-checkout-session`, data)
    }
}

export default Proxy<CurrentUser>(new CurrentUser("/users"));
