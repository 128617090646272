import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import AmazonS3Destination from "../../../models/http/data-connector/destination/amazon-s3";

class AmazonS3API extends API{
  serializer = () => ({
    _postOne : AmazonS3Destination,
    _put: AmazonS3Destination
  })
}

export default Proxy<AmazonS3API>(new AmazonS3API('/destinations/amazon-s3'));
