import { JSONSchemaType } from "ajv/dist/types/json-schema";
import DestinationModel, { Destination, PostDestination } from "../destination";

export interface AzureBlobStorage extends Destination {
  connectionString: string,
  bucketId: string,
}

export interface PostAzureBlobStorage extends PostDestination {
  connectionString: string,
  bucketId: string,
}


class AzureBlobStorageDestination extends DestinationModel<AzureBlobStorage, PostAzureBlobStorage> {

  getBucketId() {
    return this.data.bucketId;
  }

  getConnectionString() {
    return this.data.connectionString;
  }


  getSchemaToSerialize(): JSONSchemaType<AzureBlobStorage> {
    const schema = this.baseSchemaToSerialize();
    schema.properties = { ...schema.properties,
      "bucketId": {
        type: ["string"]
      },
      "connectionString": {
        type: ["string"]
      }
    };

    return schema as JSONSchemaType<AzureBlobStorage>;
  }

  getSchemaPostToValidate(): JSONSchemaType<PostDestination & PostAzureBlobStorage> {
    const schema = this.baseGetSchemaPostToValidate();
    schema.properties = { ...schema.properties,
      "bucketId": {
        type: ["string"]
      },
      "connectionString": {
        type: ["string"]
      }
    };

    return schema as JSONSchemaType<PostDestination & PostAzureBlobStorage>;
  }

}

export default AzureBlobStorageDestination;
